/* Navbar.module.css or Navbar.css */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff; /* or the specific color code from your design */
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); /* Adjust for desired shadow effect */
    padding: 0 2rem; /* Adjust padding as necessary */
    height: 80px; /* Adjust for desired navbar height */
    
  }
  
  .navbar-logo img {
    height: 100px; /* Adjust based on actual size of your logo */
    width: auto;
    margin-top: 20px;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
    z-index: 999;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: #333; /* or your preferred color */
    margin: 0 1.5rem; /* Increase the left and right margin */
    padding: 0.5rem 0; /* Optional: Adjust padding if needed */
    font-weight: bold;
    font-size: 1rem;
    /* other styles */
  }
  
  
  .navbar-links a:hover {
    border-bottom: 3px solid #007bff; /* Adjust to the highlight color from your design */
  }
  
  .navbar-actions {
    display: flex;
    align-items: center;
  }
  
  .btn-login,
  .btn-signup {
    text-decoration: none;
    border: none;
    background-color: #007bff; /* Adjust to match your design */
    color: #ffffff;
    margin-left: 1rem; /* Adjust for desired spacing */
    padding: 0.5rem 1rem; /* Adjust for desired size */
    font-size: 1rem; /* Adjust for desired font size */
    border-radius: 5px; /* Adjust if you want a different border radius */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth background transition */
  }
  
  .btn-login {
    background-color: transparent;
    color: #007bff; /* Adjust to match your design */
  }
  
  .btn-login:hover {
    background-color: #e6f3ff; /* Light blue, or adjust for your design's hover state */
  }
  
  .btn-signup:hover {
    background-color: #0056b3; /* Darker blue, adjust for your design's hover state */
  }
  

  /*Mobile Navbar */

/* Hamburger menu button */
.hamburger {
    display: none; /* Hidden by default, will show on mobile */
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .hamburger-box {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 24px;
  }
  
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #333;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
  }
  
  .hamburger-inner {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    left: 0;
  }
  
  .hamburger-inner::before {
    top: -10px;
  }
  
  .hamburger-inner::after {
    bottom: -10px;
  }
  
  /* Animation for hamburger menu when active */
  .hamburger.is-active .hamburger-inner {
    background-color: transparent;
  }
  
  .hamburger.is-active .hamburger-inner::before {
    transform: translateY(10px) rotate(45deg);
  }
  
  .hamburger.is-active .hamburger-inner::after {
    transform: translateY(-10px) rotate(-45deg);
  }
  
  /* Show menu when hamburger is clicked */
  .navbar-links.show {
    display: flex;
  }
  
  /* Hide actions when hamburger is clicked */
  .navbar-actions.hide {
    display: none;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .hamburger {
      display: block;
    }
    
    .navbar-links,
    .navbar-actions {
      position: absolute;
      right: 0;
      top: 80px; /* Height of the navbar */
      flex-direction: column;
      background-color: #ffffff; /* Adjust if needed */
      width: 100%; /* Full width */
      text-align: center;
      display: none;
    }
    
    /* Spacing for menu items */
    .navbar-links a {
      padding: 10px;
      border-bottom: 1px solid #eaeaea; /* Light border for each item */
      display: block;
    }
  
    /* Spacing for action buttons */
    .navbar-actions {
      flex-direction: row;
      justify-content: center;
      padding: 10px 0;
    }
  
    /* Show the menu links when active */
    .navbar-links.show {
      display: flex;
    }
  }
  
  /* ... existing styles ... */
  

/* ... existing styles ... */

/* Subtle background color fade effect on menu items */
.navbar-links a {
    display: inline-block;
    color: #333; /* Initial text color */
    text-decoration: none;
    padding: 10px 20px; /* Adjust padding to your liking */
    transition: background-color 0.3s ease-out, color 0.3s ease-out; /* Smooth transition for bg and text color */
  }
  
  .navbar-links a:hover,
  .navbar-links a:focus {
    background-color: rgba(255, 165, 0, 0.2); /* Soft orange background */
    color: #ff7f50; /* Orange color for the text */
    border-radius: 5px; /* Optional: adds rounded corners */
  }
  
  /* ... existing styles ... */
  
  /* Responsive styles */
  @media (max-width: 768px) {
    /* ... existing responsive styles ... */
    
    .navbar-links a:hover,
    .navbar-links a:focus {
      background-color: rgba(255, 165, 0, 0.2); /* Keep the background transition for mobile */
    }
  
    /* ... */
  }
  