.our-work-section {
    text-align: center;
    padding: 50px 0;
    position: relative;
    overflow: hidden;
  }
  
  .our-work-section h2 {
    margin-bottom: 80px;
    font-size: 2em;
  }
  
  .cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .card {
    width: 500px;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    margin: 0 20px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.5s ease;
  }
  
  .card.active {
    transform: scale(1.05);
  }
  
  .card-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background 0.5s ease-in-out;
  }
  
  .card-description {
    display: none;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
  }
  
  .card:hover .card-description {
    display: block;
  }
  
  .card-title {
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin: 0;
  }
  
  .arrow {
    position: absolute;
    top: 65%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 3;
  }
  
  .arrow img {
    width: 30px;
    height: auto;
  }
  
  .arrow.left {
    left: 10px;
  }
  
  .arrow.right {
    right: 10px;
  }
  
  /* Mobile responsive styles */
  @media (max-width: 480px) {
    .our-work-section h2 {
      font-size: 1.5em;
      margin-bottom: 30px;
    }
  
    .cards-container {
      flex-direction: column;
      align-items: center;
      overflow-x: auto;
    }
  
    .card {
      width: 80%;
      height: auto;
      margin: 10px auto;
      display: block;
    }
  
    .arrow {
      display: none;
    }
  
    .card-description,
    .card-title {
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 10px;
      position: absolute;
      bottom: 0;
      width: 100%;
      transform: translateY(100%);
      transition: transform 0.3s ease;
      text-align: center;
      font-size: 0.8em;
      display: none;
    }
  
    .card:hover .card-description {
      transform: translateY(0);
      display: block;
    }
  
    .card:hover .card-title {
      display: none;
    }
  }
  
  /* Existing styles for .our-work-section */
.our-work-section {
    text-align: center;
    padding: 50px 0;
    position: relative;
    overflow: hidden;
    /* ... other styles ... */
  }
  
  /* ... other styles ... */
  
  /* Hide .our-work-section on mobile devices */
  @media (max-width: 480px) {
    .our-work-section {
      display: none;
    }
  }
  