/* Base styles for hero section */
.hero-section {
    /* Full-screen hero section for immediate impact */
    height: 100vh;
    min-height: 500px; /* Minimum height for smaller devices */
    display: flex;
    align-items: center; /* Vertically center content */
    justify-content: center; /* Horizontally center content */
    text-align: center; /* Center text for all screen sizes */
    padding: 40px; /* Padding for text content */
    color: #333; /* Primary text color */
    background: linear-gradient( 
      rgba(0, 0, 0, 0.5), /* Overlay color */
      rgba(0, 0, 0, 0.5) /* You can adjust the alpha for transparency */
    ), url('../assets/hero-background.jpeg') no-repeat center center/cover; /* Background image */
  }
  
  .hero-content {
    max-width: 1200px; /* Maximum width of the content */
    margin: 0 auto;
    position: relative;
    z-index: 2; /* Ensure content is above any background overlay */
  }
  
  .hero-content h1 {
    font-size: 3rem; /* Dynamic font size for the main headline */
    color: #ff7f50; /* Brand color for the main headline */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for readability */
    margin-bottom: 0.5em;
  }
  
  .hero-content h2 {
    font-size: 2rem; /* Dynamic font size for sub-headlines */
    color: #fff; /* Light color for contrast */
    margin-bottom: 1em;
  }
  
  .hero-content p {
    font-size: 1.2rem; /* Comfortable reading size */
    color: #fff; /* Light color for readability */
    margin-bottom: 2em;
    max-width: 800px; /* Max width to maintain optimal line length */
    margin-left: auto; /* Center the paragraph container */
    margin-right: auto;
  }
  
  .explore-button {
    background-color: #ff7f50; /* Brand color */
    color: #fff;
    padding: 15px 30px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease-out, background-color 0.3s;
  }
  
  .explore-button:hover {
    background-color: #e56730; /* Darker shade for the button */
    transform: translateY(-3px); /* Lift button on hover */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  }
  
   
  /* Responsive styles for tablets */
  @media (min-width: 768px) {
    .hero-section {
      padding: 40px; /* Increased padding on tablets */
    }
  
    .hero-content h1 {
      font-size: 2.5em; /* Increase font size for tablets */
    }
  
    .hero-content h2 {
      font-size: 2em; /* Increase font size for tablets */
    }
  
    .hero-content p {
      font-size: 1.2em; /* Increase font size for tablets */
    }
  
    .explore-button {
      padding: 15px 30px; /* Increased padding on tablets */
      font-size: 1em; /* Increase font size for tablets */
    }
  }
  
  /* Responsive styles for desktops */
  @media (min-width: 1024px) {
    .hero-section {
      padding: 60px; /* Increased padding on desktops */
      min-height: 70vh; /* Larger minimum height for a prominent hero section */
    }
  
    .hero-content h1 {
      font-size: 3em; /* Increase font size for desktops */
    }
  
    .hero-content h2 {
      font-size: 2.2em; /* Increase font size for desktops */
    }
  
    .hero-content p {
      font-size: 1.4em; /* Increase font size for desktops */
    }
  
    .explore-button {
      padding: 20px 40px; /* Increased padding on desktops */
      font-size: 1.2em; /* Increase font size for desktops */
    }
  }
  



  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .hero-content {
    /* ... existing styles ... */
    animation: fadeInUp 1s ease-out forwards;
  }
  
  /* Optionally, you can delay the animation of individual elements: */
  .hero-content h1 {
    animation-delay: 0.3s;
  }
  
  .hero-content h2 {
    animation-delay: 0.5s;
  }
  
  .hero-content p {
    animation-delay: 0.7s;
  }
  
  .explore-button {
    animation-delay: 0.9s;
  }
  


    