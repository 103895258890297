.our-clients {
    background-color: #f4f4f4; /* Neutral background */
    color: #333; /* Dark text color for contrast */
    padding: 100px 0; /* Ample padding for airy spacing */
    text-align: center;
  }
  
  .our-clients h2 {
    font-size: 2.5rem; /* Large, readable font size */
    margin-bottom: 50px; /* Space below heading */
    font-weight: 600; /* Semi-bold for a subtle emphasis */
  }
  
  .clients-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center logos horizontally */
    gap: 40px; /* Spacing between logos */
    max-width: 1200px; /* Maximum width of the logos container */
    margin: 0 auto; /* Center the container */
  }
  
  .client-logo-container {
    width: 200px; /* Width of logo container */
    height: 200px; /* Height of logo container */
    display: flex;
    justify-content: center; /* Center logo horizontally */
    align-items: center; /* Center logo vertically */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .client-logo-container:hover {
    transform: translateY(-5px); /* Subtle lift effect on hover */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Shadow for depth */
  }
  
  .client-logo {
    max-width: 90%; /* Limit logo size, preserving original aspect ratio */
    max-height: 90%; /* Limit logo height, preserving original aspect ratio */
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .client-logo-container {
      width: 150px; /* Adjust size for tablet view */
      height: 150px;
    }
  }
  
  @media (max-width: 576px) {
    .client-logo-container {
      width: 120px; /* Adjust size for mobile view */
      height: 120px;
    }
  
    .our-clients h2 {
      font-size: 2rem; /* Slightly smaller heading for mobile screens */
    }
  }
  