.newsletter-section {
    background: url('../assets/newsletter-background.jpg') no-repeat center center/cover;
    padding: 120px 20px; /* Increased padding for more height */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px; /* Setting a minimum height */
  }
  
  .newsletter-container {
    max-width: 600px;
    background: rgba(255, 255, 255, 0.95); /* Semi-transparent white for readability */
    padding: 60px; /* Increased padding for a more spacious layout */
    border-radius: 12px; /* Soft rounded corners */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Slightly more pronounced shadow */
    backdrop-filter: blur(5px); /* Soft blur effect on the background */
  }
  
  .newsletter-title {
    margin-bottom: 25px;
    color: #333;
    font-weight: bold;
    font-size: 2.2rem; /* Slightly larger font size */
  }
  
  .newsletter-subtitle {
    margin-bottom: 40px;
    color: #555;
    font-size: 1.1rem; /* Adjust font size for the subtitle */
    max-width: 80%;
    margin-left: auto;
    margin-right: auto; /* Center subtitle */
  }
  
  .newsletter-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px; /* Provide more space between elements */
  }
  
  .newsletter-input {
    flex-grow: 1;
    padding: 15px 20px; /* More padding for a taller input field */
    border: 2px solid #ff7f50; /* Orange border to match the theme */
    border-radius: 25px; /* Pill-shaped input for modern style */
    font-size: 1rem;
  }
  
  .newsletter-button {
    background-color: #ff7f50; /* Theme color for the button */
    color: white;
    border: none;
    padding: 15px 30px; /* More padding for a taller button */
    border-radius: 25px; /* Pill-shaped button for modern style */
    font-size: 1rem;
    font-weight: bold; /* Bold text for the button */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
  }
  
  .newsletter-button:hover,
  .newsletter-button:focus { /* Hover and focus states for interactive feedback */
    background-color: #e5673b;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
    outline: none; /* Remove outline to maintain style */
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .newsletter-form {
      flex-direction: column;
      gap: 15px; /* Adjust the gap between stacked elements */
    }
  
    .newsletter-input,
    .newsletter-button {
      width: 100%; /* Full width for mobile */
    }
  }
  

  /* Keyframes for the glow animation */
@keyframes glow {
    0%, 100% {
      box-shadow: 0 0 15px #ff7f50, 0 0 30px #ff7f50, 0 0 45px #ff7f50, 0 0 60px #ff7f50;
      transform: scale(1);
    }
    50% {
      box-shadow: 0 0 20px #ff7f50, 0 0 40px #ff7f50, 0 0 60px #ff7f50, 0 0 80px #ff7f50;
      transform: scale(1.05);
    }
  }
  
  .newsletter-button {
    /* Existing styles */
    /* ... */
    position: relative; /* For the pseudo-element */
    overflow: hidden; /* Ensures the pseudo-element is clipped to the button's border-radius */
  }
  
  /* Before pseudo-element to create the extra glowing layer */
  .newsletter-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    border-radius: inherit; /* Match the button border-radius */
    z-index: -1; /* Place it under the button content */
    opacity: 0.7; /* Start with a lower opacity */
    transition: opacity 0.3s; /* Smooth transition for the opacity */
  }
  
  .newsletter-button:hover::before {
    opacity: 1; /* Full opacity on hover */
  }
  
  .newsletter-button:hover {
    animation: glow 1.5s infinite alternate; /* Apply the glow animation */
  }
  
  /* Responsive adjustments, if needed */
  @media (max-width: 768px) {
    /* ... */
  }

  
  /* Base styles for the button */
.newsletter-button {
    /* Existing styles for padding, font, border, etc. */
    padding: 10px 20px; /* Example padding */
    font-size: 1rem; /* Example font size */
    border-radius: 5px; /* Example border radius */
    /* ... */
    /* Add the animation styles provided previously */
    /* ... */
  }
  
  /* Hover effect for non-touch devices */
  @media (hover: hover) {
    .newsletter-button:hover {
      /* Glow effect on hover */
      animation: glow 1.5s infinite alternate;
    }
  }
  
  /* Responsive styles for tablets */
  @media (max-width: 768px) {
    .newsletter-button {
      padding: 8px 16px; /* Slightly smaller padding on tablets */
      font-size: 0.9rem; /* Slightly smaller font size on tablets */
    }
  }
  
  /* Responsive styles for mobile phones */
  @media (max-width: 480px) {
    .newsletter-button {
      padding: 12px 24px; /* Larger padding for a touch-friendly button on mobile */
      font-size: 1rem; /* Keep font size legible on mobile */
      width: 100%; /* Full width button on mobile for better access */
    }
  }
  
  /* Ensure the button is not too large on very wide screens */
  @media (min-width: 1200px) {
    .newsletter-button {
      max-width: 300px; /* Maximum width for the button */
      /* Center the button if the form input is also restricted in width */
      margin-left: auto;
      margin-right: auto;
    }
  }
  