.tech-stack-section {
    
    background: linear-gradient(145deg, rgba(255,165,0, 0.1) 0%, rgba(128,128,128, 0.1) 100%);
    color: #333;
    padding: 80px 15px; /* Adjusted padding for all screens */
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .tech-stack-section h2 {
    color: #ff7f50; /* Orange text to match the logo theme */
    font-size: 1.8em; /* Adjusted font size for all screens */
    margin-bottom: 1.5rem;
  }
  
  .tech-stack-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem; /* Consistent gap for a cleaner layout */
  }
  
  .tech-stack-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 120px; /* Default size for desktops and larger tablets */
    height: 120px;
    margin: 10px;
    transition: transform 0.3s;
    border-radius: 10px;
  }
  
  .tech-stack-item:hover {
    transform: scale(1.1);
  }
  
  /* Adjustments for smaller devices and ensuring visibility */
  @media (max-width: 768px) {
    .tech-stack-item {
      width: 100px; /* Smaller size for smaller tablets */
      height: 100px;
    }
  }
  
  @media (max-width: 575px) {
    .tech-stack-section h2 {
      font-size: 1.5em; /* Slightly smaller font size for mobile */
    }
  
    .tech-stack-item {
      width: 80px; /* Smaller size for mobile phones */
      height: 80px;
    }
  }
  
  

  /* Adjust logo sizes and grid spacing for various screen sizes */
@media (min-width: 361px) {
    .tech-stack-item {
      width: 30px;
      height: 30px;
    }
  }
  
  @media (min-width: 411px) {
    .tech-stack-item {
      width: 30px;
      height: 30px;
    }
  }
  
  @media (min-width: 768px) {
    .tech-stack-container {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Larger min column width for tablets and up */
    }
  
    .tech-stack-item {
      width: 100px;
      height: 100px;
    }

    .tech-stack-item img {
        width: 100px;
        height: 100px;
      }
  }



  /* Existing desktop styles */
  
  /* Media query for mobile devices */
  @media (max-width: 480px) {
    .tech-stack-container {
      grid-template-columns: repeat(auto-fit, minmax(60px, 1fr)); /* Adjust the minmax size for smaller logos */
      gap: 10px; /* Reduce gap size for closer alignment */
    }
  
    .tech-stack-item {
      width: 60px; /* Reduce the width for mobile */
      height: 60px; /* Reduce the height for mobile */
      /* ... other styles ... */
    }

    .tech-stack-item img {
        width: 50px;
    }
  
    /* You may want to adjust the font size of the section heading and any other text */
    .tech-stack-section h2 {
      font-size: 1.5em; /* Adjust heading size for mobile */
    }
  }
  