/* ServicesSection.css */

.services-section {
    background: #f9f9f9;
    padding: 80px 20px;
    text-align: center;
    font-family: 'Arial', sans-serif; /* Choose a font that fits your brand */
  }
  
  .services-title {
    position: relative;
    display: inline-block; /* Allows ::after border to fit content */
    margin-bottom: 60px;
    font-size: 2.8em;
    color: #333;
    padding-bottom: 10px; /* Space for the underline */
  }
  
  .services-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #ff7f50;
    transition: all 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .services-title:hover::after {
    width: 0; /* Hover effect that removes the underline */
  }
  
  .services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 40px;
  }
  
  .service-item {
    flex-basis: calc(50% - 40px); /* Adjust this for 2 items per row on mobile */
    max-width: 350px;
    box-sizing: border-box;
    padding: 40px;
    margin-bottom: 40px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .service-item:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow for lift effect */
  }
  
  .service-item h3 {
    color: #ff7f50; /* Brand color */
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .service-item p {
    color: #666;
    line-height: 1.6;
    font-size: 1em;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .service-item {
      flex-basis: calc(100% - 40px); /* Full width for mobile */
    }
  }
  
  @media (min-width: 769px) {
    .service-item {
      flex-basis: calc(33.333% - 40px); /* 3 items per row on desktop */
    }
  }

  
  /* ServicesSection.css */

/* ... existing styles ... */

/* Fade-in animation */
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .service-item {
    animation: fadeInUp 0.5s ease-out forwards;
    opacity: 0; /* Start with items invisible */
    /* ... other styles ... */
  }
  
  /* You can control the delay of each card's animation based on its position */
  .service-item:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .service-item:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .service-item:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  /* And so on for each card... */

  
  /* Add these styles to your ServiceSection.css file */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* High z-index to ensure it's above other content */
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    z-index: 1001; /* Even higher z-index for the modal content */
  }
  

  /* ServiceSection.css */

/* ... existing styles ... */

.service-item button {
    background-color: #ff7f50; /* Brand color */
    color: white; /* Text color */
    border: none; /* Remove default border */
    padding: 10px 20px; /* Top and bottom padding, left and right padding */
    font-size: 1em; /* Text size */
    cursor: pointer; /* Cursor indicates button */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions for hover effects */
    outline: none; /* Remove default outline */
    margin-top: 15px; /* Space from the description text */
  }
  
  .service-item button:hover, .service-item button:focus {
    background-color: #e56730; /* Darker shade on hover/focus for contrast */
    transform: translateY(-2px); /* Slight lift effect */
    outline: none; /* Ensure no outline is shown on focus */
  }
  
  /* Additional responsive adjustments or specific styles as needed */
  