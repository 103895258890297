.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 500px;
  }
  
  .modal-content h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content form input,
  .modal-content form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal-content form textarea {
    resize: vertical; /* Allows vertical resizing, might be useful for longer messages */
  }
  
  .modal-content form button[type="submit"] {
    background-color: #ff7f50;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.2s;
  }
  
  .modal-content form button[type="submit"]:hover {
    background-color: #e56730;
  }
  
  .modal-content button {
    /* Style for the close button */
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    margin-left: auto; /* Aligns button to the right */
    font-size: 1.2rem;
  }

  
  /* Enhance modal responsiveness */
@media (max-width: 768px) {
    .modal-content {
      width: 95%; /* Increase width on smaller screens for more space */
      padding: 15px; /* Adjust padding for smaller screens */
    }
  
    .modal-content form button[type="submit"],
    .modal-content button {
      padding: 12px 15px; /* Increase padding for easier interaction on touch devices */
    }
  
    .modal-content form input,
    .modal-content form textarea {
      padding: 8px; /* Adjust padding for inputs and textarea */
    }
  }
  
  /* Adjust font sizes for smaller devices, if necessary */
  @media (max-width: 480px) {
    .modal-content h2 {
      font-size: 1.5rem; /* Adjust heading size for very small screens */
    }
  
    .modal-content form input,
    .modal-content form textarea,
    .modal-content form button[type="submit"] {
      font-size: 0.9rem; /* Adjust font size for better readability */
    }
  }
  
  .toast-notification {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #28a745; /* Success color */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    z-index: 1050; /* Ensure it's above other content */
    animation: toast-in-out 3s forwards;
  }
  
  @keyframes toast-in-out {
    0%, 100% { opacity: 0; bottom: 0; }
    10%, 90% { opacity: 1; bottom: 20px; }
  }
  