.footer {
    background: #333;
    color: #ddd;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 40px;
    font-size: 0.9rem;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .footer-section {
    margin: 0 20px;
  }
  
  .footer-section h4 {
    color: #ff7f50;
    margin-bottom: 20px;
  }
  
  .footer-section ul,
  .footer-section p {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li a {
    color: #ddd;
    text-decoration: none;
    margin-bottom: 10px;
    display: block;
    transition: color 0.3s;
  }
  
  .footer-section ul li a:hover {
    color: #ff7f50;
    padding-left: 5px;
  }
  
  .footer-bottom {
    border-top: 1px solid #444;
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-bottom p {
    margin: 0;
    color: #aaa;
  }
  
  .footer-social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer-social-icons a {
    color: #ddd;
    font-size: 1.4rem;
    transition: color 0.3s;
  }
  
  .footer-social-icons a:hover {
    color: #ff7f50;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      justify-content: center;
      text-align: center;
    }
  
    .footer-section {
      margin: 10px 0;
    }
  
    .footer-bottom {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-social-icons {
      margin-top: 20px;
    }
  }
  
  